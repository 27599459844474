.fullscreen-container {
  display: flex;
  justify-content: center;
}
.ingress-fullscreeen-container {
  .owc-component-header__prefix-container div[slot="prefix"] {
    width: 100%;
  }

  .owc-card__content-wrapper .owc-workflow-overlay__slot-wrapper {
    height: 100%;
  }

  owc-component-header[slot="header"].owc-workflow-overlay__header-wrapper {
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  }

  owc-component-header {
    padding: 0 !important;
  }

  div[slot="content"] {
    padding: 0 !important;
    height: 100%;
  }

  .owc-component-footer[slot="footer"] {
    justify-content: space-between;

    .owc-component-footer__prefix-slot-wrapper div[slot="prefix"] {
      display: flex;
      gap: 24px;
    }

    .owc-component-footer__suffix-slot-wrapper div[slot="suffix"] {
      display: flex;
      gap: 24px;
    }
  }

  .footer-border-bottom {
    border-top: 1px solid var(--one-color-background-intensity-medium);
  }
}
