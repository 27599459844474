.custom-tooltip {
  padding: 6px;
  display: block;
  z-index: 10000000000000000;
  background: #312f2e;
  color: var(--one-color-accent-white);
  margin: 15px;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 4px;
  white-space: nowrap;
}

.fullscreen-container {
  display: flex;
  justify-content: center;
}

.ingress-fullscreeen-container .owc-component-header__prefix-container div[slot=prefix] {
  width: 100%;
}
.ingress-fullscreeen-container .owc-card__content-wrapper .owc-workflow-overlay__slot-wrapper {
  height: 100%;
}
.ingress-fullscreeen-container owc-component-header[slot=header].owc-workflow-overlay__header-wrapper {
  padding: 0.5rem 0.5rem 0.5rem 1rem !important;
}
.ingress-fullscreeen-container owc-component-header {
  padding: 0 !important;
}
.ingress-fullscreeen-container div[slot=content] {
  padding: 0 !important;
  height: 100%;
}
.ingress-fullscreeen-container .owc-component-footer[slot=footer] {
  justify-content: space-between;
}
.ingress-fullscreeen-container .owc-component-footer[slot=footer] .owc-component-footer__prefix-slot-wrapper div[slot=prefix] {
  display: flex;
  gap: 24px;
}
.ingress-fullscreeen-container .owc-component-footer[slot=footer] .owc-component-footer__suffix-slot-wrapper div[slot=suffix] {
  display: flex;
  gap: 24px;
}
.ingress-fullscreeen-container .footer-border-bottom {
  border-top: 1px solid var(--one-color-background-intensity-medium);
}

.asset-definition-preview-container .owc-workflow-overlay__backdrop-element__overlay-element {
  position: absolute;
  right: 0;
  width: 1100px !important;
}
.asset-definition-preview-container .owc-component-header__prefix-container div[slot=prefix] {
  width: 100%;
}
.asset-definition-preview-container .owc-card__content-wrapper .owc-workflow-overlay__slot-wrapper {
  height: 100%;
}
.asset-definition-preview-container owc-component-header[slot=header].owc-workflow-overlay__header-wrapper {
  padding: 0.5rem 0.5rem 0.5rem 1rem !important;
}
.asset-definition-preview-container owc-component-header {
  padding: 0 !important;
}
.asset-definition-preview-container div[slot=content] {
  padding: 0 !important;
  height: 100%;
}
.asset-definition-preview-container .owc-component-footer[slot=footer] {
  justify-content: space-between;
}
.asset-definition-preview-container .owc-component-footer[slot=footer] .owc-component-footer__prefix-slot-wrapper div[slot=prefix] {
  display: flex;
  gap: 24px;
}
.asset-definition-preview-container .owc-component-footer[slot=footer] .owc-component-footer__suffix-slot-wrapper div[slot=suffix] {
  display: flex;
  gap: 24px;
}
.asset-definition-preview-container .footer-border-bottom {
  border-top: 1px solid var(--one-color-background-intensity-medium);
}
.asset-definition-preview-container .close {
  cursor: pointer;
}